import React from 'react';
import styles from './ByMethodScaleData.module.scss';

const ByMethodScaleData = ({ scale }) => {
    const colors = ['#219653', '#E42036', '#2F80ED', '#FECC62', '#812FED'];
    const maxVal = [...scale.levels].sort((a, b) => b - a)[0];

    return (
        <div className={styles.byMethodScaleData}>
            <p className={styles.heading}>{scale.name}</p>
            <div className={styles.scales}>
                {scale.levels.map((item, i) => {
                    const levelWidth = item / (maxVal / 100);
                    return (
                        <div className={styles.scale} key={i}>
                            <div className={styles.scaleHolder}>
                                <div
                                    style={{ width: levelWidth + '%', backgroundColor: colors[i] }}
                                    className={styles.scaleLine}
                                />
                                <div className={styles.scaleSeparators}>
                                    {Array(19)
                                        .fill(0)
                                        .map((_, i) => {
                                            return <div className={styles.separator} key={i} />;
                                        })}
                                </div>
                                <div className={styles.scaleNumbers}>
                                    {Array(6)
                                        .fill(0)
                                        .map((item, index) => {
                                            return (
                                                <div className={styles.scaleNumber} key={index}>
                                                    <p className={styles.scaleNumberText}>
                                                        {index > 0 && index < 5
                                                            ? Math.ceil(index * (maxVal / 5))
                                                            : index * (maxVal / 5)}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ByMethodScaleData;
