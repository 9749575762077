import React from 'react';

import { classNames as cn } from 'helpers/classNames';
import styles from './StatisticByParamTable.module.scss';

const StatisticByParamTablePsychs = ({ rows }) => {
    return (
        <div className={styles.table}>
            <div className={styles.headingGrid}>
                <p className={styles.headingText}>ФИО</p>
                <p className={styles.headingText}>Муниципалитет</p>
                <p className={cn([styles.headingText, styles.textRight])}>Школа</p>
            </div>
            <div className={styles.rows}>
                {rows?.length > 0 &&
                    rows?.map((row, index) => {
                        return (
                            <div key={index} className={styles.tableGrid}>
                                <p className={styles.tableRowText}>{row?.fullName}</p>
                                <p className={styles.tableRowText}>{row?.municipality}</p>
                                <p className={cn([styles.tableRowText, styles.textRight])}>{row?.school}</p>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default StatisticByParamTablePsychs;
